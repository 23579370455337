import React, { useContext } from 'react'
import PageNotFound from '../../pages/404'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Table from 'components/Table'
import Ruble from 'components/Ruble'
import Button from 'components/Button'
import Secondary from 'components/Secondary'
import { Col, Grid, Row } from 'react-flexbox-grid'
import bem from 'bem'
import css from './Cart.module.scss'
import Calc2 from 'components/Calc2'
import Contacts from 'components/Contacts'
import Empty from '../../pages/404'
import StoreContext from 'contexts/store'
import OrdersContext from 'contexts/orders'
import Link from 'gatsby-link'
import useDeferredActive from 'hooks/useDeferredActive'
import declension from 'utils/declension'
import Promo from './components/Promo'
import Increment from 'components/Increment'
import Mark from 'components/Mark'
import Tooltip from 'components/Tooltip'

const b = bem.sceneCart(css)

const Cart = () => {
	const storeContext = useContext(StoreContext)
	const isDisabled = useDeferredActive(storeContext.data.loading, 600)

	const { data } = storeContext
	const { databases, summary } = data

	const ordersContext = useContext(OrdersContext)

	// Сервер или клиент
	const isClient = typeof window !== 'undefined'

	// До инициализации скриптов, серверная версия содержит пустую корзину
	if (!isClient) return null

	if (!databases.length)
		return (
			<PageNotFound
				title="Ваша корзина пуста"
				text="Вы не добавили еще ни одной базы"
				buttonText="Выбрать базу"
				buttonURL="/dbs.html#list"
			/>
		)

	const Loading = ({ size }) => <div className={b('loading', { size })}>&nbsp;</div>

	const columns = [
		{
			key: 'name',
			title: 'Название',
		},
		{
			key: 'price',
			title: 'Цена',
		},
		{
			key: 'discount',
			title: 'Скидка',
		},
		{
			key: 'priceTotal',
			title: 'Цена со скидкой',
		},
		{
			key: 'action',
			title: null,
		},
	]

	let source = databases.map(product => {
		return {
			name:
				typeof product.name !== 'undefined' ? (
					<strong>
						<Link to={`/db-${product.type}.html`}>{product.name}</Link>&nbsp;&nbsp;
						{product.priceOld && (
							<Mark bold color="green">
								&minus;{Math.ceil(100 - (product.price * 100) / product.priceOld)}%
							</Mark>
						)}
					</strong>
				) : (
					<Loading size="md" />
				),
			price:
				typeof product.price !== 'undefined' ? (
					<div>
						<strong className="hidden-no-mobile">Цена: </strong>
						<Ruble>{product.price}</Ruble>
					</div>
				) : (
					<Loading size="sm" />
				),
			discount:
				typeof product.discountPercent !== 'undefined' ? (
					<div>
						<strong className="hidden-no-mobile">Скидка: </strong>
						{product.discountPercent}%
					</div>
				) : (
					<Loading size="sm" />
				),
			priceTotal:
				typeof product.priceTotal !== 'undefined' ? (
					<div>
						<strong className="hidden-no-mobile">Цена со скидкой: </strong>
						<Ruble>{product.priceTotal}</Ruble>
					</div>
				) : (
					<Loading size="sm" />
				),
			action: product.name && (
				<Button
					className={b('button-delete', { loading: product.loading })}
					icon={product.loading ? 'loading' : 'trash'}
					disabled={product.loading || isDisabled}
					size="sm"
					theme="link"
					onClick={() => storeContext.deleteDatabase(product.type)}
				>
					<span className="hidden-no-mobile">Удалить&nbsp;базу</span>
				</Button>
			),
		}
	})

	const promo = storeContext.data.promo || {}

	const tooltipDiscount = {
		0: 'Больше баз, больше скидка',
		1: 'Вторая база со скидкой 20%',
		2: 'Третья база со скидкой 25%',
	}

	source.push({
		options: {
			row: {
				background: false,
				class: b('table-row-promo'),
			},
		},
		name: (
			<div className={b('promo')}>
				<Promo />
			</div>
		),
		price: <div className={b('table-row-promo-value', 'hidden-xs')}>&minus;</div>,
		discount: (
			<div className={b('table-row-promo-value', 'hidden-xs')}>
				{promo.isActual ? <Ruble>{promo.discount}</Ruble> : <span>&minus;</span>}
			</div>
		),
		priceTotal: <div className={b('table-row-promo-value', 'hidden-xs')}>&minus;</div>,
		action: null,
	})

	source.push({
		options: {
			row: {
				background: false,
				class: b('table-row-total'),
			},
		},
		name: <strong className="hidden-xs">Итого</strong>,
		price: (
			<div className="hidden-xs text-nowrap">
				<strong>
					<Increment isFormat>{summary.price}</Increment>
					<Ruble />
				</strong>
			</div>
		),
		discount: (
			<div className="hidden-xs text-nowrap">
				<strong>
					<Increment isFormat>{summary.discount}</Increment>
					<Ruble />
				</strong>
			</div>
		),
		priceTotal: (
			<div>
				<span className="hidden-no-mobile">Итого: </span>
				{/* Рядом с блоком, который не исчезает на мобильных */}
				<Tooltip
					placement="bottom"
					animation="zoom"
					overlay={tooltipDiscount[databases.length] || tooltipDiscount[0]}
					visible
				>
					<strong className="text-lg text-nowrap">
						<Increment isFormat>{summary.priceTotal}</Increment>
						<Ruble />
					</strong>
				</Tooltip>
			</div>
		),
		action: null,
	})

	const initialValues = {
		type: 'online',
		databases: databases.map(database => ({ name: database.type })),
		isSendToEmail: true,
		promo: promo.name,
	}

	return (
		<Layout className={b()}>
			<SEO title="Корзина товаров — «Парсик»" />

			<Sector>
				<h1 className="text-center mb-lg">Ваша корзина</h1>
				<div className={b('table', 'text-lg')}>
					<Table columns={columns} source={source} priorityWidth={['name']} />
				</div>
			</Sector>

			<Sector color="gray">
				<Calc2
					title="Способы оплаты"
					rounding={1}
					initialValues={initialValues}
					services={[
						{
							field: {
								type: 'radio',
								name: 'type',
								value: 'online',
								children: `Онлайн на сайте`,
								helper: '',
								size: 'lg',
							},
							fields: [
								{
									field: {
										type: 'checkbox',
										name: 'isSendToEmail',
										children: `Хочу получить ${declension(databases.length, 'базы', 'базу', 'базы')} на почту`,
									},
									fields: [
										{
											field: {
												type: 'email',
												name: 'email',
												prefix: 'mail',
												placeholder: 'Ваш email-адрес',
											},
										},
									],
								},
							],
							title: 'Стоимость',
							price: summary.priceTotal,
							isShowPrice: true,
							inner: {
								component: {
									type: 'custom',
									render: props => {
										const { values, isLoading, isError } = props
										return (
											<div>
												<Secondary className="mb">
													После оплаты вы&nbsp;сможете скачать {declension(databases.length, 'базы', 'базу', 'базы')}{' '}
													на&nbsp;сайте
													{values.isSendToEmail && (
														<span>, а&nbsp;так&nbsp;же отправим вам на&nbsp;электронную почту</span>
													)}
												</Secondary>
												<p className="mb">
													<Button
														type="submit"
														theme="success"
														icon="card"
														loading={isLoading}
														disabled={storeContext.data.loading || isLoading || isError}
													>
														Перейти к оплате
													</Button>
												</p>
											</div>
										)
									},
								},
							},
							endpoint: '/store/order/create',
							callback: async response => {
								// Добавление ID заказа в LocalStorage для вывода подсказки после оплаты
								ordersContext.add(response.clientOrderId)
								window.location.href = response.redirectUrl
							},
						},
						{
							field: {
								type: 'radio',
								name: 'type',
								value: 'invoice',
								children: `Выставить счет на организацию`,
								helper: '',
								size: 'lg',
							},
							title: 'Стоимость',
							price: summary.priceTotal,
							isShowPrice: true,
							inner: {
								component: {
									type: 'contactForm',
									props: {
										fields: [
											{
												type: 'name',
												name: 'invoice-name',
												placeholder: 'Название компании',
												required: true,
											},
											{
												type: 'text',
												name: 'invoice-inn',
												icon: 'briefcase',
												placeholder: 'ИНН компании',
												required: true,
											},
											{
												type: 'email',
												name: 'email',
												placeholder: 'Email для получения счета',
												required: true,
											},
										],
										buttonTitle: 'Выставить счет',
										buttonTitleSuccess: 'Отправлено',
										buttonTheme: 'success',
										buttonIcon: 'document',
										textSuccess: (
											<>
												<span className="h5 mb-xs">Сейчас отправим счет на вашу почту</span>
												<br />
												Проверьте папку <strong>Спам</strong> и <strong>Нежелательная почта</strong>
											</>
										),
									},
								},
								textBefore: (
									<p>
										Укажите реквизиты компании, на&nbsp;которую выставить счет. Мы&nbsp;отправим его на&nbsp;ваш
										email-адрес
									</p>
								),
								textAfter: null,
							},
							endpoint: '/store/order/invoice',
						},
						{
							field: {
								type: 'radio',
								name: 'type',
								value: 'other',
								children: `Другой способ`,
								helper: 'Перевод на&nbsp;карту, криптовалюта и&nbsp;т.д.',
								isHelperStatic: true,
								size: 'lg',
							},
							title: 'Стоимость',
							price: summary.priceTotal,
							isShowPrice: true,
							inner: {
								component: {
									type: 'contactForm',
									props: {
										fields: [
											{
												type: 'name',
												name: 'other-name',
												placeholder: 'Ваше имя *',
												required: true,
											},
											{
												type: 'email',
												name: 'email',
												placeholder: 'Email-адрес *',
												required: true,
											},
											{
												type: 'phone',
												name: 'phone',
												placeholder: 'Телефон',
											},
											{
												type: 'textarea',
												name: 'other-message',
												placeholder: 'Напишите, как вам удобно оплатить',
											},
										],
										buttonTitle: 'Отправить',
										buttonTitleSuccess: 'Отправлено',
										buttonTheme: 'success',
										buttonIcon: 'check',
										textSuccess: 'Скоро свяжемся с вами. Ожидайте пожалуйста',
									},
								},
								textBefore: <p>Напишите нам, обсудим варианты оплаты</p>,
								textAfter: null,
							},
							endpoint: '/store/order/other',
						},
					]}
				/>
			</Sector>

			<Sector>
				<Grid fluid>
					<Row>
						<Col lgOffset={1} lg={10}>
							<div className="mb-lg">
								<h2 className="text-center">Получите скидку</h2>
								<p className="text-lg text-center mb-lg">Купите несколько баз и получите хорошую скидку</p>
								<ul className="fs-h4">
									<li>
										Скидка <strong>20%</strong> - на 2-ю базу
									</li>
									<li>
										Скидка <strong>25%</strong> - на 3-4 базы
									</li>
									<li>
										Скидка <strong>30%</strong> - на 5-9 базы
									</li>
									<li>
										Скидка <strong>40%</strong> - на 10-14 базы
									</li>
									<li>
										Скидка <strong>50%</strong> - на 15-ю и последующие базы
									</li>
								</ul>
								<Secondary>
									<p>Скидка применяется за единовременную покупку сразу нескольких баз</p>
									<p>Скидка действует на менее дорогие базы</p>
								</Secondary>
							</div>
							<Button theme="primary" to="/dbs.html#list" isLink>
								Добавить еще базу
							</Button>
						</Col>
					</Row>
				</Grid>
			</Sector>

			<Contacts color="gray" />
		</Layout>
	)
}

const CartEmpty = () => (
	<Empty
		title="Ваша корзина пуста"
		text="Вы не добавили еще ни одной базы"
		buttonText="Выбрать базу"
		buttonURL="/dbs.html#list"
	/>
)

const _Cart = () => {
	const isEmpty = 0
	return !isEmpty ? <Cart /> : <CartEmpty />
}

export default _Cart
